import { Combobox, MenuPage } from "@daybridge/components"
import { useTranslations } from "next-intl"
import { useCallback } from "react"
import { Place, Location } from "../../data/locations/types"
import { useLocationOperations } from "../../data/locations/useLocations"
import { useIconsMenu } from "../icons/icons-menu"
import { IconId } from "../../data/_gen/types"

export type PlaceOptionsMenuInstanceOptions = {
  placeOrLocation: string | Place | Location
  onAddToSavedLocations?: (place: string | Place) => void
  onRenameLocation?: (location: Location) => void
}

type PlaceOptionsMenuInstanceGenerator = (
  opts: PlaceOptionsMenuInstanceOptions,
) => MenuPage

export const useLocationAndPlaceOptionsMenu =
  (): PlaceOptionsMenuInstanceGenerator => {
    const t = useTranslations("locations")
    const tNoResults = useTranslations("default_no_results_state")

    const {
      update: { mutateAsync: updateLocation },
      delete: { mutateAsync: deleteLocation },
    } = useLocationOperations()

    const iconsMenu = useIconsMenu()

    const items = useCallback(
      (opts: PlaceOptionsMenuInstanceOptions): MenuPage["items"] => [
        ...(typeof opts.placeOrLocation !== "string" &&
        (opts.placeOrLocation._type !== "Location" ||
          (opts.placeOrLocation._type === "Location" &&
            opts.placeOrLocation.suggested))
          ? [
              {
                id: "add-to-saved-locations",
                title: t("add_to_saved_locations"),
                icon: "Book",
                onSelect: () => {
                  opts.onAddToSavedLocations?.(opts.placeOrLocation as Place)
                },
              },
            ]
          : []),
        ...(typeof opts.placeOrLocation !== "string" &&
        opts.placeOrLocation._type === "Location" &&
        !opts.placeOrLocation.suggested
          ? [
              {
                id: "rename",
                title: t("rename"),
                icon: "Pencil",
                onSelect: () => {
                  opts.onRenameLocation?.(opts.placeOrLocation as Location)
                },
              },
              {
                id: "icon",
                title: t("choose_icon"),
                icon: opts.placeOrLocation.icon || "MapPin",
                expandChildrenAs: {
                  component: Combobox,
                  mode: "combobox" as const,
                },
                children: iconsMenu(
                  opts.placeOrLocation.icon ? [opts.placeOrLocation.icon] : [],
                  (icon: string | undefined) => {
                    void updateLocation({
                      input: {
                        id: (opts.placeOrLocation as Location).id,
                        patch: {
                          icon: icon
                            ? {
                                id: icon as IconId,
                              }
                            : null,
                        },
                      },
                    })
                  },
                  true,
                ),
              },
              {
                groupId: "remove",
                items: [
                  {
                    id: "remove-from-saved-locations",
                    title: t("remove_from_saved_locations"),
                    icon: "Cross",
                    hue: 0,
                    onSelect: () => {
                      void deleteLocation({
                        input: {
                          id: (opts.placeOrLocation as Location).id,
                        },
                      })
                    },
                  },
                ],
              },
            ]
          : []),
        ...(typeof opts.placeOrLocation !== "string" &&
        opts.placeOrLocation._type === "Location" &&
        opts.placeOrLocation.suggested
          ? [
              {
                id: "hide-suggestion",
                title: t("hide_suggestion"),
                icon: "Cross",
                onSelect: () => {
                  void deleteLocation({
                    input: {
                      id: (opts.placeOrLocation as Location).id,
                    },
                  })
                },
              },
            ]
          : []),
      ],
      [t, deleteLocation, updateLocation, iconsMenu],
    )

    return useCallback(
      (opts: PlaceOptionsMenuInstanceOptions): MenuPage => ({
        noResultsTitle: tNoResults("no_results"),
        noResultsDescription: tNoResults("no_results_description"),
        items: items(opts),
      }),
      [items, tNoResults],
    )
  }
