import { useMemo } from "react"
import { useAccount } from "../../data/account/useAccount"
import { useUpdateAccount } from "../../data/account/useUpdateAccount"
import { useCountriesMenu } from "./countries-menu"

export const useCountrySettingsMenu = () => {
  const cm = useCountriesMenu({
    selectionMode: "radio",
  })

  const { data: account } = useAccount()
  const { mutate: updateAccount } = useUpdateAccount()

  const cmBound = useMemo(() => {
    return cm({
      selectedItems: account?.country ? [account.country] : [],
      onItemSelect: (country) => {
        updateAccount({
          input: {
            patch: {
              countryCode: country.code,
            },
          },
        })
      },
    })
  }, [cm, account, updateAccount])

  return cmBound
}
