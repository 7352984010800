import React, { useCallback, useMemo, useState } from "react"
import { Combobox, Popover, PopoverProps } from "@daybridge/components"
import { cn } from "@daybridge/cn"
import {
  TagsMenuGeneratorOptions,
  TagsMenuOptions,
  useTagsMenu,
} from "./tags-menu"
import { useCreateTagMenu } from "./create-tag-menu"

export type TagsPickerProps = Omit<PopoverProps, "content"> &
  TagsMenuOptions &
  TagsMenuGeneratorOptions

const TagsPickerFn = React.forwardRef(
  (props: TagsPickerProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const {
      children,
      className,
      selectionMode,
      partitioned,
      showTagEditMenu,
      showNoTagOption,
      noTagOptionSelected,
      onNoTagChange,
      selectedTags,
      onTagSelect,
      allowReorder,
      ...rest
    } = props

    const [open, setOpen] = React.useState(false)

    return (
      <Popover
        open={open}
        onOpenChange={setOpen}
        content={
          <TagsCombobox
            selectionMode={selectionMode}
            partitioned={partitioned}
            showTagEditMenu={showTagEditMenu}
            showNoTagOption={showNoTagOption}
            noTagOptionSelected={noTagOptionSelected}
            onNoTagChange={onNoTagChange}
            onComplete={() => setOpen(false)}
            selectedTags={selectedTags}
            onTagSelect={onTagSelect}
            allowReorder={allowReorder}
          />
        }
        className={cn("w-80", className)}
        updatePositionStrategy="always"
        ref={ref}
        {...rest}
      >
        {children}
      </Popover>
    )
  },
)
TagsPickerFn.displayName = "TagsPicker"
export const TagsPicker = React.memo(TagsPickerFn) as typeof TagsPickerFn

export type TagsComboboxProps = {
  onComplete?: () => void
  showCreateTag?: boolean
} & TagsMenuOptions &
  TagsMenuGeneratorOptions
const TagsComboboxFn = React.forwardRef(
  (props: TagsComboboxProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const {
      onComplete,
      selectionMode,
      partitioned,
      showTagEditMenu,
      showNoTagOption,
      noTagOptionSelected,
      onNoTagChange,
      selectedTags,
      onTagSelect,
      allowReorder,
      showCreateTag = true,
    } = props

    const [creating, setCreating] = useState(false)
    const createMenu = useCreateTagMenu(() => {
      setCreating(true)
    })
    const onCreateComplete = useCallback(() => {
      setCreating(false)
    }, [])

    const root = useTagsMenu({
      selectionMode,
      partitioned,
      showTagEditMenu,
      showNoTagOption,
      noTagOptionSelected,
      onNoTagChange,
      creating,
      onCreateComplete,
    })

    const rootBound = useMemo(() => {
      return root({
        selectedTags,
        onTagSelect,
        allowReorder,
      })
    }, [root, selectedTags, onTagSelect, allowReorder])

    return (
      <Combobox
        ref={ref}
        root={rootBound}
        onComplete={onComplete}
        pinnedItems={showCreateTag ? [createMenu] : []}
      />
    )
  },
)
TagsComboboxFn.displayName = "TagsCombobox"
export const TagsCombobox = React.memo(TagsComboboxFn) as typeof TagsComboboxFn
