import { useTranslations } from "next-intl"
import React, { useCallback, useMemo } from "react"
import { Dropdown, Label, MenuItem, MenuItemGroup } from "@daybridge/components"
import { TimeZoneWithDeltaDescription } from "../../data/geodata/geodata"
import { useClientData } from "../../data/client-data/useClientData"
import { useGeoData } from "../../data/geodata/useGeoData"
import { useEditAdditionalTimeZones } from "../../data/timezones/useEditAdditionalTimeZones"
import { styleForHue } from "../../lib/styleForHue"
import { useTimeZone } from "../../app/[locale]/(boundary)/_providers/TimeZoneProvider"
import { useUserTimeZones } from "../../data/timezones/useUserTimeZones"
import { TimeZone } from "../../data/_gen/types"
import { useTimeZoneCustomizationsMenu } from "./time-zone-customizations-menu"

export const useTimeZonesMenu = (
  selectedZones?: TimeZone[],
  onZoneSelect?: (timeZone: TimeZone) => void,
  selectionMode?: MenuItemGroup["selectionMode"],
) => {
  const t = useTranslations("time_zones")
  const tNoResults = useTranslations("default_no_results_state")

  const { data } = useGeoData()
  const { timeZonesByRegion } = data || {}
  const { effective, system } = useTimeZone()
  const [userTimeZoneCustomizations] = useClientData("timeZoneCustomizations")
  const [renamingZone, setRenamingZone] = React.useState<string | undefined>()
  const { setZoneCustomName } = useEditAdditionalTimeZones()
  const customizationsMenu = useTimeZoneCustomizationsMenu(setRenamingZone)

  const timeZone = useCallback(
    (
      zone: TimeZoneWithDeltaDescription,
      selected: boolean,
      onSelect: () => void,
    ): MenuItem => {
      const customization = userTimeZoneCustomizations?.[zone.id]
      return {
        id: zone.id,
        hue: customization?.hue,
        title: customization?.name ? `${customization.name}` : zone.city,
        description:
          [customization?.name ? zone.city : undefined, zone.deltaDescription]
            .filter(Boolean)
            .join(" — ") ?? undefined,
        renaming: renamingZone === zone.id,
        maxNameLength: 64,
        onRename: (name) => {
          setZoneCustomName(zone.id, name)
        },
        onRenameCancel: () => {
          setRenamingZone(undefined)
        },
        rightContent:
          zone.id === effective ? (
            <Label
              className="text-sm"
              theme={customization?.hue !== undefined ? "adaptive" : "info"}
              style={
                {
                  ...styleForHue(customization?.hue),
                } as React.CSSProperties
              }
            >
              {t("current")}
            </Label>
          ) : zone.id === system ? (
            <Label
              className="text-sm"
              theme={customization?.hue !== undefined ? "adaptive" : "info"}
              style={
                {
                  ...styleForHue(customization?.hue),
                } as React.CSSProperties
              }
            >
              {t("system")}
            </Label>
          ) : undefined,
        icon: "Globe",
        selected,
        onSelect,
        maxSearchDepth: 1,
        searchStrings: [zone.id],
        children: customizationsMenu(zone),
        expandChildrenAs: {
          mode: "menu",
          component: Dropdown,
        },
      }
    },
    [
      effective,
      userTimeZoneCustomizations,
      t,
      system,
      customizationsMenu,
      renamingZone,
      setZoneCustomName,
    ],
  )

  const val = useMemo(
    () => ({
      noResultsTitle: tNoResults("no_results"),
      noResultsDescription: tNoResults("no_results_description"),
      prompt: t("search_time_zones") + "...",
      promptIcon: "Globe",
      items: Object.entries(timeZonesByRegion || {}).map(
        ([region, cities]) => ({
          id: region,
          title: region,
          icon: "World",
          description: t("n_time_zones", { n: cities.length }),
          maxSearchDepth: 0,
          children: {
            prompt: t("search_time_zones_in_region", { region }) + "...",
            promptIcon: "Globe",
            defaultHighlightedValue: selectedZones?.[0]?.id,
            items: [
              {
                groupId: "cities",
                selectionMode,
                items: cities.map((city) =>
                  timeZone(
                    city,
                    selectedZones?.find((z) => z.id === city.id) !== undefined,
                    () => onZoneSelect?.(city),
                  ),
                ),
              },
            ],
          },
        }),
      ),
    }),
    [
      tNoResults,
      t,
      timeZonesByRegion,
      timeZone,
      selectedZones,
      onZoneSelect,
      selectionMode,
    ],
  )

  return val
}

export const useHomeTimeZoneMenu = () => {
  const { effective, setTimeZoneOverride, clearTimeZoneOverride } =
    useTimeZone()
  const { data } = useGeoData()
  const { timeZones } = data || {}
  if (!timeZones) {
    throw new Error("Time zones not loaded")
  }

  const selectedZoneIds = useMemo(() => [], [])

  const onSelect = useCallback(
    (zone: TimeZone) => {
      if (zone.id === effective) {
        clearTimeZoneOverride(true)
      } else {
        setTimeZoneOverride(zone.id, true)
      }
    },
    [clearTimeZoneOverride, effective, setTimeZoneOverride],
  )

  return useTimeZonesMenu(selectedZoneIds, onSelect, "radio")
}
export const useAdditionalTimeZonesMenu = () => {
  const userZones = useUserTimeZones()
  const { addZone, removeZone } = useEditAdditionalTimeZones()

  const { data } = useGeoData()
  const { timeZones } = data || {}
  if (!timeZones) {
    throw new Error("Time zones not loaded")
  }

  const onZoneSelect = useCallback(
    (zone: TimeZone) => {
      if (userZones.find((z) => z.id === zone.id)) {
        removeZone(zone.id)
      } else {
        addZone(zone.id)
      }
    },
    [addZone, removeZone, userZones],
  )

  return useTimeZonesMenu(userZones, onZoneSelect, "checkbox")
}
