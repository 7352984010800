import { useTranslations } from "next-intl"
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { Button } from "@daybridge/components"
import { AlertDialog } from "../../../../components/alert-dialog/AlertDialog"
import useAppVersion from "../../../../lib/useAppVersion"
import { useNow } from "../../../../lib/useNow"
import { Link } from "../../../../components/link/Link"

export type AppUpdateContext = {
  // `updateAvailable` is true if the latest version is newer than the current version
  // This will remain true after the user presses "remind me later", and will only become
  // false after the app has been reloaded.
  updateAvailable: boolean

  // `updateDialogOpen` is true if the update dialog is currently being shown to the user, pending
  // their response.
  updateDialogOpen: boolean

  // `resetReminder` will reset the "remind me later" state, and show the update dialog again
  resetReminder: () => void
}

const appUpdateContext = createContext<AppUpdateContext>({
  updateAvailable: false,
  updateDialogOpen: false,
  resetReminder: () => {
    throw new Error("resetReminder can only be used within AppUpdateProvider")
  },
})

const AppUpdateContextProvider = appUpdateContext.Provider
export const useAppUpdateAvailable = () => useContext(appUpdateContext)

/**
 * `AppUpdateProvider` is a provider that will show an alert dialog to the user if a new version
 * of the app is available. The alert dialog will be shown for every new version, and will be
 * shown again if the user presses "remind me later". If more than 48 hours have passed since
 * the latest version was released, the app will automatically reload.
 */
export const AppUpdateProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const t = useTranslations("app")

  // Refresh the version every minute
  const refreshInterval = 60 * 1000
  const now = useNow(refreshInterval)
  const version = useAppVersion(refreshInterval)

  // If "remind me later" is pressed, then remind the user after 12 hours
  const reminderTimeout = 1000 * 60 * 60 * 12

  // If the user presses "remind me later", we'll store the version they dismissed in state
  // and not show the update dialog again until the next version is released, or a 24 hour
  // period has passed.
  const [dismissedVersion, setDismissedVersion] = useState<string | null>(null)

  // The alert should be shown if the latest version is newer than the dismissed version
  const showAlert =
    version.updateAvailable && version.release !== dismissedVersion

  const resetReminder = useCallback(() => {
    setDismissedVersion(null)
  }, [])

  // Callback to set the dismissed version
  const dismissVersion = useCallback(() => {
    setDismissedVersion(version.release)

    // Reset the dismissed version after 24 hours
    setTimeout(() => {
      resetReminder()
    }, reminderTimeout)
  }, [version.release, reminderTimeout, resetReminder])

  // If the latest version was made available more than 48 hours ago, we will
  // force the app to reload
  const forceReload =
    version.updateAvailable && now.diff(version.releasedAt).as("hours") > 48

  useEffect(() => {
    if (forceReload) {
      window.location.reload()
    }
  }, [forceReload])

  return (
    <AppUpdateContextProvider
      value={{
        updateAvailable: version.updateAvailable,
        updateDialogOpen: showAlert,
        resetReminder,
      }}
    >
      {children}
      <AlertDialog
        open={showAlert}
        title={t("update_available")}
        description={t("update_available_description")}
        actionButtonText={t("update")}
        actionButtonTheme="warning"
        onActionPress={() => {
          dismissVersion()
          window.location.reload()
        }}
        cancelButtonText={t("remind_me_later")}
        onCancelPress={dismissVersion}
        content={
          <div className="text-base mt-4">
            <Link href="https://daybridge.com/changelog" target="_blank">
              <Button icon="Star" variant="link" theme="brand-blue">
                {t("see_whats_new")}
              </Button>
            </Link>
          </div>
        }
      />
    </AppUpdateContextProvider>
  )
}
