import { MenuItemGroup } from "@daybridge/components"
import { useTranslations } from "next-intl"
import { useCallback, useMemo } from "react"
import { useBeginFlow } from "../../app/[locale]/(boundary)/_providers/FlowProvider"

export const useDaybridgeMenu = (): MenuItemGroup => {
  const t = useTranslations("daybridge")

  const beginFlow = useBeginFlow()

  const onBlogSelect = useCallback(() => {
    window.open("https://daybridge.com/blog", "_blank")
  }, [])

  const onChangelogSelect = useCallback(() => {
    window.open("https://daybridge.com/changelog", "_blank")
  }, [])

  return useMemo(
    (): MenuItemGroup => ({
      groupId: "daybridge",
      title: "Daybridge",
      items: [
        {
          id: "blog",
          title: t("blog"),
          icon: "SpeechBubble",
          onSelect: onBlogSelect,
        },
        {
          id: "changelog",
          title: t("changelog"),
          icon: "Book",
          onSelect: onChangelogSelect,
        },
        {
          id: "feedback",
          title: t("send_feedback"),
          icon: "Megaphone",
          onSelect: () => beginFlow({ id: "feedback_triage" }),
        },
      ],
    }),
    [onBlogSelect, onChangelogSelect, t, beginFlow],
  )
}
