import {
  Root,
  Trigger,
  Portal,
  Overlay,
  Content,
  Title,
  Description,
  Cancel,
  Action,
} from "@radix-ui/react-alert-dialog"
import React, {
  ComponentProps,
  ForwardedRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react"
import { Icon, Button } from "@daybridge/components"

export interface AlertDialogProps {
  // Optional. If set then this should be a button which will trigger
  // the alert to open when pressed.
  children?: React.ReactElement

  // `open` is a boolean that determines whether the alert is open or not.
  // If not provided, the open/closed state will be managed automatically. This is only
  // useful if you want to control the open/closed state manually.
  open?: boolean

  // `defaultOpen` is a boolean that determines whether the alert is initially open.
  // Only useful if `open` is not provided.
  defaultOpen?: boolean

  // `onOpenChange` is a callback that is called when the alert is opened or closed.
  // If manually controlling the open/closed state, this callback should be used to
  // update the state.
  onOpenChange?: (open: boolean) => void

  // Alert contents
  icon?: string
  title?: React.ReactNode
  description?: React.ReactNode
  content?: React.ReactNode

  // `actionButtonText` is the text to display on the action button.
  actionButtonText?: string

  // `actionButtonVariant` is the variant of the action button, for example
  // destructive for red butttons.
  actionButtonTheme?: ComponentProps<typeof Button>["theme"]

  // `onActionPress` is a callback that is called when the action button is pressed.
  onActionPress?: () => void
  onCancelPress?: () => void

  // Cancel button text.
  hideCancelButton?: boolean
  cancelButtonText?: string

  // Additional classes to apply to the modal body. Ignored if `headless` is true.
  className?: string
}

export interface AlertDialogImperativeHandle {
  open: () => void
  close: () => void
}

const AlertDialogFn = React.forwardRef(
  (props: AlertDialogProps, ref?: React.ForwardedRef<HTMLButtonElement>) => {
    const {
      children,
      open,
      defaultOpen,
      onOpenChange,
      icon,
      title,
      description,
      content,
      actionButtonText,
      actionButtonTheme,
      onActionPress,
      onCancelPress,
      hideCancelButton,
      cancelButtonText,
      className,
      ...rest
    } = props

    // If the open/closed state is not provided, we'll manage it internally
    const [openState, setOpenState] = useState(false)

    // Combine the open/closed state in the props with the internal state
    const isOpen = open !== undefined ? open : openState
    const onOpenChangeCallback = useCallback(
      (open: boolean) => {
        onOpenChange && onOpenChange(open)
        setOpenState(open)
      },
      [onOpenChange, setOpenState],
    )

    // Set up an imperative API to allow the parent component to control the open/closed state
    // without having to manage the internal state.
    useImperativeHandle(
      ref as ForwardedRef<AlertDialogImperativeHandle>,
      () => ({
        open: () => {
          onOpenChangeCallback(true)
        },
        close: () => {
          onOpenChangeCallback(false)
        },
      }),
    )

    return (
      <Root
        open={isOpen}
        defaultOpen={defaultOpen}
        onOpenChange={onOpenChangeCallback}
      >
        {children && (
          <Trigger ref={ref} {...rest} asChild>
            {children}
          </Trigger>
        )}

        <Portal>
          <Overlay
            className="
              data-[state=open]:animate-[fade-in_100ms_ease-in]
              data-[state=closed]:animate-[fade-out_100ms_ease-in]
              fixed inset-0 bg-black/10 z-20 dark:bg-black/50
            "
          />

          <Content
            className={`
              w-full max-w-md z-30
              fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
              bg-surface
              shadow-sm
              rounded-2xl
              text-left
              p-6
              ${props.className || ""}
              pointer-events-auto
              data-[state=open]:animate-[fade-and-scale-in_100ms_ease-in] 
              data-[state=closed]:animate-[fade-and-scale-out_100ms_ease-in] 
            `}
            onEscapeKeyDown={(event) => {
              // Don't allow the user to escape if there's
              // no cancel button.
              if (hideCancelButton === true) {
                event.preventDefault()
              }

              // Stop the event from propagating to the parent
              // so that modals etc don't close.
              event.stopPropagation()
            }}
          >
            <div className="flex flex-col items-start space-y-3">
              {icon && (
                <div className="flex-shrink-0">
                  <Icon name={icon} className="mt-1 w-4 text-low-contrast" />
                </div>
              )}
              <div className="flex-1 flex flex-col">
                {title && <Title>{title}</Title>}
                {description && (
                  <Description className="text-base text-medium-contrast">
                    {description}
                  </Description>
                )}
              </div>
            </div>
            {content}
            <div
              className="
                mt-6
                flex flex-row items-center justify-end
                space-x-1.5
              "
            >
              {!hideCancelButton && (
                <Cancel asChild>
                  <Button onClick={onCancelPress} variant="translucent">
                    {cancelButtonText || "Cancel"}
                  </Button>
                </Cancel>
              )}
              <Action asChild>
                <Button
                  onClick={onActionPress}
                  variant="solid"
                  theme={actionButtonTheme || "brand-blue"}
                >
                  {actionButtonText || "OK"}
                </Button>
              </Action>
            </div>
          </Content>
        </Portal>
      </Root>
    )
  },
)
AlertDialogFn.displayName = "AlertDialog"

export const AlertDialog = React.memo(AlertDialogFn) as typeof AlertDialogFn
