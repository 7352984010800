import { useCallback, useMemo } from "react"
import { useClientData } from "../client-data/useClientData"
import { IconId } from "../_gen/types"
import { useTimeZone } from "../../app/[locale]/(boundary)/_providers/TimeZoneProvider"

export const MAX_ZONES = 8

export interface EditTimeZoneOperations {
  addZone: (zoneName: string | null) => void
  removeZone: (zoneName: string) => void
  setZoneCustomName: (zoneName: string, newName: string | undefined) => void
  setZoneHue: (zoneName: string, hue: number | undefined) => void
  setZoneIcon: (zoneName: string, icon: IconId | undefined) => void
  moveZone: (zoneName: string, direction: "left" | "right") => void
  reorderZones: (newOrder: string[]) => void
}

export const useEditAdditionalTimeZones = () => {
  const [timeZones, setTimeZones] = useClientData("additionalTimeZones")
  const [customizations, setCustomizations] = useClientData(
    "timeZoneCustomizations",
  )
  const { effective } = useTimeZone()

  const addZone = useCallback(
    (zoneName: string | null) => {
      if (zoneName === null) return

      // Don't add duplicates
      if (timeZones.includes(zoneName)) return

      // Limit length to MAX_ZONES
      if (timeZones.length >= MAX_ZONES) return

      setTimeZones([...timeZones, zoneName])
    },
    [setTimeZones, timeZones],
  )

  const removeZone = useCallback(
    (zoneName: string) => {
      setTimeZones(timeZones.filter((name) => name !== zoneName))
    },
    [setTimeZones, timeZones],
  )

  const setZoneCustomName = useCallback(
    (zoneName: string, newName: string | undefined) => {
      if (!customizations) return
      setCustomizations({
        ...customizations,
        [zoneName]: {
          ...customizations[zoneName],
          name: newName === "" ? undefined : newName,
        },
      })
    },
    [customizations, setCustomizations],
  )

  const setZoneHue = useCallback(
    (zoneName: string, hue: number | undefined) => {
      if (!customizations) return
      setCustomizations({
        ...customizations,
        [zoneName]: {
          ...customizations[zoneName],
          hue,
        },
      })
    },
    [customizations, setCustomizations],
  )

  const setZoneIcon = useCallback(
    (zoneName: string, icon: IconId | undefined) => {
      if (!customizations) return
      setCustomizations({
        ...customizations,
        [zoneName]: {
          ...customizations[zoneName],
          icon,
        },
      })
    },
    [customizations, setCustomizations],
  )

  const moveZone = useCallback(
    (zoneName: string, direction: "left" | "right") => {
      let newTimeZones = [...timeZones]
      const indexOfCurrentZone = newTimeZones.indexOf(effective)
      if (indexOfCurrentZone === -1) {
        // Add timezone to the beginning if not found and set index to 0
        newTimeZones.unshift(effective)
      }

      const index = newTimeZones.indexOf(zoneName)
      if (index === -1) {
        return
      }

      // Limit length to MAX_ZONES
      newTimeZones = newTimeZones.slice(0, MAX_ZONES)

      const newIndex = direction === "left" ? index - 1 : index + 1
      if (newIndex >= 0 && newIndex < newTimeZones.length) {
        // Swap the elements in the array
        ;[newTimeZones[index], newTimeZones[newIndex]] = [
          newTimeZones[newIndex],
          newTimeZones[index],
        ]
      }
      setTimeZones(newTimeZones)
    },
    [setTimeZones, timeZones, effective],
  )

  const reorderZones = useCallback(
    (newOrder: string[]) => {
      setTimeZones(newOrder)
    },
    [setTimeZones],
  )

  return useMemo<EditTimeZoneOperations>(
    () => ({
      addZone,
      removeZone,
      setZoneCustomName,
      setZoneHue,
      setZoneIcon,
      moveZone,
      reorderZones,
    }),
    [
      addZone,
      removeZone,
      setZoneCustomName,
      setZoneHue,
      setZoneIcon,
      moveZone,
      reorderZones,
    ],
  )
}
