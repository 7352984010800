import { MenuItem } from "@daybridge/components"
import { useTranslations } from "next-intl"
import { useMemo } from "react"
import { useBeginFlow } from "../../app/[locale]/(boundary)/_providers/FlowProvider"

export const useCreateCalendarMenu = (): MenuItem => {
  const begin = useBeginFlow()
  const t = useTranslations("calendars")

  return useMemo(
    (): MenuItem => ({
      id: "create-calendar",
      title: t("create_calendar"),
      icon: "Plus",
      onSelect: () => begin({ id: "calendar_creation" }),
    }),
    [t, begin],
  )
}
