import { MenuPage } from "@daybridge/components"
import { useTranslations } from "next-intl"
import { useMemo } from "react"
import { useClientData } from "../../data/client-data/useClientData"

export const useLayoutMenu = (): MenuPage => {
  const tNoResults = useTranslations("default_no_results_state")
  const t = useTranslations("layout")

  const [layout, setLayout] = useClientData("webLayout")

  const items = useMemo(
    (): MenuPage["items"] => [
      {
        groupId: "layout",
        selectionMode: "radio",
        items: [
          {
            id: "timeline",
            title: t("week"),
            icon: "ViewWeekOutline",
            selected: layout === "timeline",
            onSelect: () => setLayout("timeline"),
          },
          {
            id: "grid",
            title: t("month"),
            icon: "ViewMonthOutline",
            selected: layout === "grid",
            onSelect: () => setLayout("grid"),
          },
        ],
      },
    ],
    [t, layout, setLayout],
  )

  return useMemo(
    (): MenuPage => ({
      noResultsTitle: tNoResults("no_results"),
      noResultsDescription: tNoResults("no_results_description"),
      prompt: t("choose_a_layout"),
      promptIcon: "Layout",
      defaultHighlightedValue: layout,
      items,
    }),
    [items, tNoResults, t, layout],
  )
}
