import { MenuPage } from "@daybridge/components"
import { useTranslations } from "next-intl"
import { useMemo } from "react"
import { useClientData } from "../../data/client-data/useClientData"

const densities = [
  "very_compact",
  "compact",
  "normal",
  "spacious",
  "very_spacious",
] as const

export const useDensityMenu = (): MenuPage => {
  const t = useTranslations("density")
  const tNoResults = useTranslations("default_no_results_state")
  const [density, setDensity] = useClientData("webDensity")

  const items = useMemo(
    (): MenuPage["items"] => [
      {
        groupId: "densitites",
        selectionMode: "radio",
        items: densities.map((d) => ({
          id: d,
          title: t(d),
          onSelect: () => setDensity(d),
          selected: density === d,
        })),
      },
    ],

    [density, setDensity, t],
  )

  return useMemo(
    (): MenuPage => ({
      noResultsTitle: tNoResults("no_results"),
      noResultsDescription: tNoResults("no_results_description"),
      prompt: t("choose_a_density"),
      promptIcon: "Views",
      items,
    }),
    [items, tNoResults, t],
  )
}
