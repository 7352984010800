import { DateTime } from "luxon"
import React, { createContext, useCallback, useContext, useState } from "react"
import { throttle } from "lodash"
import { roundTime } from "../../../../../lib/datetime"

/**
 * DateTimeInteractionContext is the React context created for storing and
 * providing the hovered date time and its type information (date or not date).
 */
export type DateTimeInteractionContext = {
  // The date time the user has hovered over
  dateTime: DateTime

  // Whether this should be treated as a date only (no time component)
  isDate: boolean

  // Rounded values - for efficiency we compute these and make them available
  // to components that need them. We also apply performance optimisations here
  // to make sure the DateTime objects do not change if the underlying rounded time
  // value is the same, which avoids unnecessary re-renders.
  rounded5DateTime: DateTime
  rounded15DateTime: DateTime
}
const dateTimeInteractionContext = createContext<DateTimeInteractionContext>({
  dateTime: DateTime.now().startOf("day"),
  isDate: false,
  rounded5DateTime: DateTime.now().startOf("day"),
  rounded15DateTime: DateTime.now().startOf("day"),
})
export const useDateTimeInteraction = () =>
  useContext(dateTimeInteractionContext)

/**
 * Passive dateTimeInteractionContext allows components to get a reference to the
 * current value of the DateTimeInteractionContext without causing a re-render
 * when the value changes. This is useful for components that need to access the
 * value but don't need to re-render when it changes.
 */
export const usePassiveDateTimeInteraction: () => React.MutableRefObject<DateTimeInteractionContext> =
  () => {
    const value = useContext(dateTimeInteractionContext)
    const ref = React.useRef(value)
    React.useEffect(() => {
      ref.current = value
    }, [value])
    return ref
  }

/**
 * PropagateDateTimeInteractionContext provides a function for components to
 * propagate the hovered date time and its type (date or not date) to the
 * DateTimeInteractionProvider so that other components can access the hovered
 * date time and its type. The PropagateDateTimeInteractionContext is separate from
 * the DateTimeInteractionContext to prevent unnecessary re-renders of components
 * that don't need to depend on the value of this context, they only need to
 * propagate new values.
 */
export type PropagateDateTimeInteractionContext = (
  // The date time the user has hovered over
  dateTime: DateTime,
  // Whether this should be treated as a date only (no time component)
  isDate: boolean,
) => void
const propagateDateTimeInteractionContext =
  createContext<PropagateDateTimeInteractionContext>(() => {
    throw new Error(
      "PropagateDateTimeInteraction can only be used in a DateTimeInteractionProvider",
    )
  })
export const usePropagateDateTimeInteraction = () =>
  useContext(propagateDateTimeInteractionContext)

type DateTimeInteractionProviderProps = {
  children: React.ReactNode
}
export const DateTimeInteractionProvider = (
  props: DateTimeInteractionProviderProps,
) => {
  const { children } = props
  const [value, setValue] = useState<DateTimeInteractionContext>({
    dateTime: DateTime.now().startOf("day"),
    isDate: false,
    rounded5DateTime: DateTime.now().startOf("day"),
    rounded15DateTime: DateTime.now().startOf("day"),
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const propagateDateTimeInteraction = useCallback(
    throttle((dateTime: DateTime, isDate: boolean) => {
      const update = (
        prev: DateTimeInteractionContext,
      ): DateTimeInteractionContext => {
        const startOfDay = dateTime.startOf("day")

        // Always round down to the start of the day if this is a date
        const newDateTime = isDate ? startOfDay : dateTime

        // Don't update the value if the underlying time is the same
        if (prev.dateTime?.equals(newDateTime) && prev.isDate === isDate) {
          return prev
        }

        // Calculate the rounded time values
        const rounded5time = isDate ? startOfDay : roundTime(dateTime, 5)
        const rounded15time = isDate ? startOfDay : roundTime(dateTime, 15)

        return {
          dateTime: newDateTime,
          isDate,

          // Only return the new objects if they are different from the existing ones
          rounded5DateTime: rounded5time.equals(prev.rounded5DateTime)
            ? prev.rounded5DateTime
            : rounded5time,
          rounded15DateTime: rounded15time.equals(prev.rounded15DateTime)
            ? prev.rounded15DateTime
            : rounded15time,
        }
      }

      setValue(update)
    }, 25),
    [],
  )

  return (
    <propagateDateTimeInteractionContext.Provider
      value={propagateDateTimeInteraction}
    >
      <dateTimeInteractionContext.Provider value={value}>
        {children}
      </dateTimeInteractionContext.Provider>
    </propagateDateTimeInteractionContext.Provider>
  )
}
