import { MenuItem, MenuItemGroup } from "@daybridge/components"
import { useTranslations } from "next-intl"
import { useMemo } from "react"
import { useClientData } from "../../data/client-data/useClientData"
import { useDensityMenu } from "./density-menu"
import {
  useHideHoursBeforeMenu,
  useHideHoursAfterMenu,
} from "./hidden-hours-menu"
import { useLayoutMenu } from "./layout-menu"
import { useWeekStartDayMenu } from "./week-start-day-menu"

export const useLayoutSettingsMenu = (): MenuItemGroup => {
  const layoutMenu = useLayoutMenu()
  const tLayout = useTranslations("layout")

  const densityMenu = useDensityMenu()
  const tDensity = useTranslations("density")

  const startWeekOn = useWeekStartDayMenu()
  const tStartWeekOn = useTranslations("week_start_day")

  const [declinedItems, setDeclinedItems] = useClientData(
    "webShowDeclinedItems",
  )
  const tDeclinedItems = useTranslations("declined_items")

  const hideHoursBeforeMenu = useHideHoursBeforeMenu()
  const hideHoursAfterMenu = useHideHoursAfterMenu()
  const tHiddenHours = useTranslations("hidden_hours")

  const tTimeZones = useTranslations("time_zones")
  const [showAdditionalTimeZones, setShowAdditionalTimeZones] = useClientData(
    "webShowAdditionalTimeZones",
  )

  const items = useMemo(
    (): MenuItem[] => [
      {
        id: "change-layout",
        title: tLayout("layout"),
        icon: "Layout",
        children: layoutMenu,
      },
      {
        id: "change-density",
        title: tDensity("density"),
        icon: "Views",
        children: densityMenu,
      },
      {
        id: "change-start-week-on",
        title: tStartWeekOn("start_week_on"),
        icon: "Calendar",
        children: startWeekOn,
      },
      {
        id: "hide-hours-before",
        title: tHiddenHours("day_start_time"),
        icon: "Sun",
        children: hideHoursBeforeMenu,
      },
      {
        id: "hide-hours-after",
        title: tHiddenHours("day_end_time"),
        icon: "Night",
        children: hideHoursAfterMenu,
      },
      {
        id: "show-declined-items",
        title: tDeclinedItems("show_declined_events"),
        icon: "Cross",
        selected: declinedItems,
        onSelect: () => setDeclinedItems(!declinedItems),
      },
      {
        id: "show-additional-time-zones",
        title: tTimeZones("show_additional_time_zones"),
        icon: "Globe",
        selected: showAdditionalTimeZones,
        onSelect: () => setShowAdditionalTimeZones(!showAdditionalTimeZones),
      },
    ],
    [
      densityMenu,
      hideHoursAfterMenu,
      hideHoursBeforeMenu,
      layoutMenu,
      tDensity,
      tStartWeekOn,
      startWeekOn,
      tHiddenHours,
      tLayout,
      tDeclinedItems,
      tTimeZones,
      declinedItems,
      setDeclinedItems,
      showAdditionalTimeZones,
      setShowAdditionalTimeZones,
    ],
  )

  return useMemo(
    (): MenuItemGroup => ({
      groupId: "layout",
      title: tLayout("layout"),
      selectionMode: "switch",
      items,
    }),
    [items, tLayout],
  )
}
