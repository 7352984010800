import { ColorPicker, Combobox, MenuPage } from "@daybridge/components"
import { useTimeZone, useTranslations } from "next-intl"
import { useCallback } from "react"
import { useEditAdditionalTimeZones } from "../../data/timezones/useEditAdditionalTimeZones"
import { useClientData } from "../../data/client-data/useClientData"
import { IconId, TimeZone } from "../../data/_gen/types"
import { useUserTimeZones } from "../../data/timezones/useUserTimeZones"
import { useIconsMenu } from "../icons/icons-menu"

export const useTimeZoneCustomizationsMenu = (
  onRenameStart?: (zoneName: string) => void,
): ((
  timeZone: TimeZone,
  renaming?: string,
  onRename?: (name: string) => void,
  onRenameCancel?: () => void,
) => MenuPage) => {
  const t = useTranslations("time_zones")
  const userTimeZones = useUserTimeZones()
  const [customizations] = useClientData("timeZoneCustomizations")

  const effective = useTimeZone()
  const { setZoneHue, moveZone, setZoneIcon, removeZone } =
    useEditAdditionalTimeZones()
  const iconsMenu = useIconsMenu()

  const items = useCallback(
    (zone: TimeZone): MenuPage["items"] => {
      const custom = userTimeZones?.find((z) => z.id === zone.id)
      return [
        ...(onRenameStart
          ? [
              {
                id: "rename",
                title: t("change_name"),
                icon: "Pencil",
                maxSearchDepth: 0,
                onSelect: () => {
                  onRenameStart?.(zone.id)
                },
              },
            ]
          : []),
        {
          groupId: "color-picker",
          items: [
            <ColorPicker
              className="p-2"
              key="color_picker"
              selectedHue={customizations?.[zone.id]?.hue}
              onHueSelect={(hue) => {
                setZoneHue(zone.id, hue)
              }}
            />,
            ...(customizations?.[zone.id]?.hue !== undefined
              ? [
                  {
                    id: "remove_color",
                    title: t("remove_color"),
                    icon: "Cross",
                    maxSearchDepth: 0,
                    onSelect: () => {
                      setZoneHue(zone.id, undefined)
                    },
                  },
                ]
              : []),
          ],
        },
        ...(custom
          ? [
              {
                id: "choose-icon",
                title: t("choose_icon"),
                icon: custom.icon ?? IconId.Globe,
                children: iconsMenu(
                  custom.icon ? [custom.icon] : undefined,
                  (icon) => {
                    setZoneIcon(zone.id, icon)
                  },
                  true,
                ),
                expandChildrenAs: {
                  mode: "combobox" as const,
                  component: Combobox,
                },
              },
              {
                groupId: "move",
                items: [
                  ...(userTimeZones?.[0].id !== zone.id
                    ? [
                        {
                          id: "move_left",
                          title: t("move_left"),
                          icon: "ArrowLeft",
                          maxSearchDepth: 0,
                          onSelect: () => {
                            moveZone(zone.id, "left")
                          },
                        },
                      ]
                    : []),
                  ...(userTimeZones?.[userTimeZones.length - 1].id !== zone.id
                    ? [
                        {
                          id: "move_right",
                          title: t("move_right"),
                          icon: "ArrowRight",
                          maxSearchDepth: 0,
                          onSelect: () => {
                            moveZone(zone.id, "right")
                          },
                        },
                      ]
                    : []),
                ],
              },
              ...(zone.id !== effective
                ? [
                    {
                      id: "remove",
                      title: t("remove"),
                      icon: "Cross",
                      hue: 0,
                      maxSearchDepth: 0,
                      onSelect: () => {
                        removeZone(zone.id)
                      },
                    },
                  ]
                : []),
            ]
          : []),
      ]
    },
    [
      t,
      customizations,
      setZoneHue,
      moveZone,
      removeZone,
      effective,
      userTimeZones,
      onRenameStart,
      iconsMenu,
      setZoneIcon,
    ],
  )

  return useCallback(
    (zone: TimeZone): MenuPage => ({
      items: items(zone),
    }),
    [items],
  )
}
