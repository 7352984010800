import { MenuPage } from "@daybridge/components"
import { useTranslations } from "next-intl"
import { useMemo } from "react"
import { useClientData } from "../../data/client-data/useClientData"

export const useTimeFormatMenu = (): MenuPage => {
  const tNoResults = useTranslations("default_no_results_state")
  const t = useTranslations("clock_format")

  const [format, setFormat] = useClientData("webTimeFormat")

  const items = useMemo(
    (): MenuPage["items"] => [
      {
        groupId: "time-format",
        selectionMode: "radio",
        items: [
          {
            id: "12_HOUR",
            title: t("12_hour"),
            icon: "Clock",
            selected: format === "12_hour",
            onSelect: () => setFormat("12_hour"),
          },
          {
            id: "24_HOUR",
            title: t("24_hour"),
            icon: "Clock",
            selected: format === "24_hour",
            onSelect: () => setFormat("24_hour"),
          },
        ],
      },
    ],
    [t, format, setFormat],
  )

  return useMemo(
    (): MenuPage => ({
      noResultsTitle: tNoResults("no_results"),
      noResultsDescription: tNoResults("no_results_description"),
      prompt: t("show_time_as") + "...",
      promptIcon: "Clock",
      defaultHighlightedValue: format,
      items,
    }),
    [items, tNoResults, t, format],
  )
}
