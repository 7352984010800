import { MenuPage, useToast } from "@daybridge/components"
import { useTranslations } from "next-intl"
import { useTheme } from "next-themes"
import { useCallback, useMemo } from "react"

export const useThemeMenu = (): MenuPage => {
  const t = useTranslations("theme")
  const toast = useToast()
  const { theme = "system", setTheme } = useTheme()

  const themeChangedToast = useCallback(
    () =>
      toast.success({
        title: t("theme_changed"),
        action: theme
          ? {
              label: t("undo"),
              onClick: () => {
                setTheme(theme)
              },
            }
          : undefined,
      }),
    [theme, setTheme, toast, t],
  )

  return useMemo(
    (): MenuPage => ({
      defaultHighlightedValue: `theme-${theme}`,
      prompt: t("choose_a_theme"),
      promptIcon: "Theme",
      items: [
        {
          groupId: "theme",
          selectionMode: "radio",
          items: [
            {
              id: "theme-light",
              title: t("light"),
              icon: "Sun",
              selected: theme === "light",
              onSelect: () => {
                themeChangedToast()
                setTheme("light")
              },
            },
            {
              id: "theme-dark",
              title: t("dark"),
              icon: "Night",
              selected: theme === "dark",
              onSelect: () => {
                themeChangedToast()
                setTheme("dark")
              },
            },
            {
              id: "theme-system",
              title: t("system"),
              icon: "Theme",
              selected: theme === "system",
              onSelect: () => {
                themeChangedToast()
                setTheme("system")
              },
            },
          ],
        },
      ],
    }),

    [setTheme, theme, themeChangedToast, t],
  )
}
