import { DateTime } from "luxon"
import { useCallback, useEffect, useMemo, useState } from "react"
import useInterval from "./useInterval"

export type AppVersion = {
  environment: string
  release: string
  releasedAt: string
}

/**
 * The `useAppVersion` hook is used to check the latest deployed version of the app. It is used
 * to determine if the app needs to update.
 *
 * @param checkInterval How often to check for a new version in ms
 * @returns The latest deployed version of the app, which may be different from the current version
 */
const useAppVersion = (checkInterval = 60000) => {
  const thisVersion = process.env.NEXT_PUBLIC_VERCEL_ENV
    ? process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA
    : process.env.NEXT_PUBLIC_DEV_SESSION_KEY

  const [version, setVersion] = useState<AppVersion>({
    environment: process.env.NEXT_PUBLIC_VERCEL_ENV || "local",
    release: thisVersion || "",
    releasedAt: process.env.NEXT_PUBLIC_BUILD_TIME || "",
  })

  const checkVersion = useCallback(async () => {
    try {
      const res = await fetch("/version")
      const data = (await res.json()) as AppVersion
      setVersion(data)
    } catch (e) {
      // Fail quietly and log an error
      console.error("⚠️ Error checking app version", e)
    }
  }, [])

  useInterval(() => void checkVersion(), checkInterval)

  const releasedAt = DateTime.fromISO(version.releasedAt)

  const versionData = useMemo(
    () => ({
      environment: version.environment,
      release: version.release,
      releasedAt: releasedAt.isValid
        ? releasedAt
        : process.env.NEXT_PUBLIC_BUILD_TIME
        ? DateTime.fromISO(process.env.NEXT_PUBLIC_BUILD_TIME)
        : DateTime.utc(),
      currentRelease: thisVersion,
      updateAvailable: thisVersion !== version.release,
    }),
    [version, releasedAt, thisVersion],
  )

  // Console Table version data when it changes
  useEffect(() => {
    console.debug("📲 App Version Information")
    console.table([
      {
        releasedAt: versionData.releasedAt.toISO(),
        currentRelease: versionData.currentRelease,
        latestRelease: versionData.release,
        updateAvailable: versionData.updateAvailable,
      },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    versionData.releasedAt.toISO(),
    versionData.currentRelease,
    versionData.release,
    versionData.updateAvailable,
  ])

  return versionData
}

export default useAppVersion
