import { MenuPage } from "@daybridge/components"
import { range } from "lodash"
import { DateTime } from "luxon"
import { useTranslations } from "next-intl"
import { useMemo } from "react"
import { useClientData } from "../../data/client-data/useClientData"
import { useTimeFormatter } from "../../lib/useTimeFormatter"

export const useHideHoursBeforeMenu = (): MenuPage => {
  const t = useTranslations("hidden_hours")
  const tNoResults = useTranslations("default_no_results_state")
  const [startDayOn, setStartDayOn] = useClientData("webStartDayOn")
  const formatter = useTimeFormatter()

  const items = useMemo(
    (): MenuPage["items"] => [
      {
        groupId: "hide-hours-before",
        selectionMode: "radio",
        items: range(0, 10).map((hour) => ({
          id: hour.toString(),
          title:
            hour === 0
              ? t("see_all_morning_hours")
              : formatter(
                  DateTime.utc().set({ hour, minute: 0 }),
                  DateTime.TIME_SIMPLE,
                ),
          onSelect: () => setStartDayOn(hour),
          selected: startDayOn === hour,
        })),
      },
    ],
    [formatter, setStartDayOn, startDayOn, t],
  )

  return useMemo(
    (): MenuPage => ({
      noResultsTitle: tNoResults("no_results"),
      noResultsDescription: tNoResults("no_results_description"),
      prompt: t("set_start_time") + "...",
      defaultHighlightedValue: startDayOn.toString(),
      items,
    }),
    [items, tNoResults, t, startDayOn],
  )
}

export const useHideHoursAfterMenu = (): MenuPage => {
  const t = useTranslations("hidden_hours")
  const tNoResults = useTranslations("default_no_results_state")
  const [endDayOn, setEndDayOn] = useClientData("webEndDayOn")
  const formatter = useTimeFormatter()

  const items = useMemo(
    (): MenuPage["items"] => [
      {
        groupId: "hide-hours-after-options",
        selectionMode: "radio",
        items: [24].concat(range(15, 24)).map((hour) => ({
          id: hour.toString(),
          title:
            hour === 24
              ? t("see_all_evening_hours")
              : formatter(
                  DateTime.utc().set({ hour, minute: 0 }),
                  DateTime.TIME_SIMPLE,
                ),
          onSelect: () => setEndDayOn(hour),
          selected: endDayOn === hour,
        })),
      },
    ],
    [endDayOn, formatter, setEndDayOn, t],
  )

  return useMemo(
    (): MenuPage => ({
      noResultsTitle: tNoResults("no_results"),
      noResultsDescription: tNoResults("no_results_description"),
      prompt: t("set_end_time") + "...",
      defaultHighlightedValue: endDayOn.toString(),
      items,
    }),
    [items, tNoResults, t, endDayOn],
  )
}
