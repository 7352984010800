import { useCallback } from "react"
import { useClientData } from "../client-data/useClientData"

export const useShowHideTag = () => {
  const [hiddenTags, setHiddenTags] = useClientData("webHiddenTagIds")

  return useCallback(
    (TagId: string, displayed: boolean) => {
      // Check if the Tag is already in the list of hidden Tags
      const alreadyHidden = hiddenTags.includes(TagId)

      // If the Tag is already hidden and we want to hide it, do nothing
      if (alreadyHidden && !displayed) return

      // If the Tag is not hidden and we want to show it, do nothing
      if (!alreadyHidden && displayed) return

      // Otherwise, update the list of hidden Tags
      const newHiddenTags = displayed
        ? hiddenTags.filter((id) => id !== TagId)
        : [...hiddenTags, TagId]

      return setHiddenTags(newHiddenTags)
    },
    [setHiddenTags, hiddenTags],
  )
}
