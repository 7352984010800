import { Dropdown, Icon, MenuPage } from "@daybridge/components"
import { useCallback, useState } from "react"
import { useTranslations } from "next-intl"
import { cn } from "@daybridge/cn"
import {
  useLocationOperations,
  useLocations,
} from "../../data/locations/useLocations"
import { Location } from "../../data/locations/types"
import { useLocationAndPlaceOptionsMenu } from "./location-place-options"

type LocationsMenuInstanceGenerator = () => MenuPage

export const useLocationsMenu = (): LocationsMenuInstanceGenerator => {
  const { data: locations } = useLocations()

  const {
    update: { mutateAsync: updateLocation },
    reorder: { mutate: reorderLocations },
  } = useLocationOperations()

  const t = useTranslations("locations")

  const locationOptions = useLocationAndPlaceOptionsMenu()
  const [renaming, setRenaming] = useState<string | undefined>(undefined)

  const items = useCallback(
    (): MenuPage["items"] => [
      {
        groupId: "locations",
        onReorder: (_, newOrder) => {
          void reorderLocations(newOrder)
        },
        items: locations.map((location: Location) => {
          return {
            id: location.id,
            title: location.customName || location.place?.name || "",
            description: location.customName
              ? location.place?.name
              : location.place?.description,
            icon: location.icon ?? "Book",
            children: locationOptions({
              placeOrLocation: location,
              onRenameLocation: (l) => setRenaming(l.id),
            }),
            expandChildrenAs: {
              component: Dropdown,
              mode: "menu",
            },
            renaming: renaming === location.id,
            onRenameCancel: () => setRenaming(undefined),
            onRename: (newName) => {
              void updateLocation({
                input: {
                  id: location.id,
                  patch: {
                    customName: newName,
                  },
                },
              })
              setRenaming(undefined)
            },
          }
        }),
      },
    ],
    [locations, locationOptions, renaming, updateLocation, reorderLocations],
  )

  return useCallback(
    (): MenuPage => ({
      emptyStateIcon: "MapPin",
      emptyStateTitle: t("no_saved_locations"),
      emptyStateDescription: t.rich("no_saved_locations_description", {
        Bookmark: () => (
          <Icon name="Book" className={cn("inline", "w-3 h-3")} />
        ),
      }),
      items: items(),
    }),
    [items, t],
  )
}
