import { MenuPage } from "@daybridge/components"
import { useTranslations } from "next-intl"
import { useMemo } from "react"
import { DayOfWeek } from "../../data/_gen/types"
import { useAccount } from "../../data/account/useAccount"
import { useUpdateAccount } from "../../data/account/useUpdateAccount"

export const useWeekStartDayMenu = (): MenuPage => {
  const t = useTranslations("week_start_day")
  const tNoResults = useTranslations("default_no_results_state")
  const { data: account } = useAccount()
  const { mutate: update } = useUpdateAccount()
  const weekStart = account?.weekStart

  const items = useMemo(
    (): MenuPage["items"] => [
      {
        groupId: "days",
        selectionMode: "radio",
        items: [
          {
            id: "sunday",
            title: t("sunday"),
            onSelect: () =>
              update({ input: { patch: { weekStart: DayOfWeek.Sunday } } }),
            selected: weekStart === DayOfWeek.Sunday,
          },
          {
            id: "monday",
            title: t("monday"),
            onSelect: () =>
              update({ input: { patch: { weekStart: DayOfWeek.Monday } } }),
            selected: weekStart === DayOfWeek.Monday,
          },
          {
            id: "tuesday",
            title: t("tuesday"),
            onSelect: () =>
              update({ input: { patch: { weekStart: DayOfWeek.Tuesday } } }),
            selected: weekStart === DayOfWeek.Tuesday,
          },
          {
            id: "wednesday",
            title: t("wednesday"),
            onSelect: () =>
              update({ input: { patch: { weekStart: DayOfWeek.Wednesday } } }),
            selected: weekStart === DayOfWeek.Wednesday,
          },
          {
            id: "thursday",
            title: t("thursday"),
            onSelect: () =>
              update({ input: { patch: { weekStart: DayOfWeek.Thursday } } }),
            selected: weekStart === DayOfWeek.Thursday,
          },
          {
            id: "friday",
            title: t("friday"),
            onSelect: () =>
              update({ input: { patch: { weekStart: DayOfWeek.Friday } } }),
            selected: weekStart === DayOfWeek.Friday,
          },
          {
            id: "saturday",
            title: t("saturday"),
            onSelect: () =>
              update({ input: { patch: { weekStart: DayOfWeek.Saturday } } }),
            selected: weekStart === DayOfWeek.Saturday,
          },
        ],
      },
    ],

    [t, update, weekStart],
  )

  return useMemo(
    (): MenuPage => ({
      noResultsTitle: tNoResults("no_results"),
      noResultsDescription: tNoResults("no_results_description"),
      prompt: t("start_week_on"),
      items,
    }),
    [items, tNoResults, t],
  )
}
