import React, { useMemo } from "react"
import {
  Combobox,
  MenuItem,
  MenuItemGroup,
  Popover,
  PopoverProps,
} from "@daybridge/components"
import { cn } from "@daybridge/cn"
import { useTranslations } from "next-intl"
import { TimeZone } from "../../data/_gen/types"
import { useTimeZonesMenu } from "./time-zones-menu"

const TimeZonePickerFn = React.forwardRef(
  (
    props: Omit<PopoverProps, "content"> &
      Omit<TimeZoneComboboxProps, "onComplete">,
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    const {
      children,
      className,
      selectedZones,
      onZoneSelect,
      otherTimeZonesTitle,
      selectionMode,
      showFloatingOption,
      showSystemDefaultOption,
      ...rest
    } = props
    const [open, setOpen] = React.useState(false)

    return (
      <Popover
        open={open}
        onOpenChange={setOpen}
        content={
          <TimeZoneCombobox
            selectedZones={selectedZones}
            onZoneSelect={onZoneSelect}
            selectionMode={selectionMode}
            onComplete={() => setOpen(false)}
            otherTimeZonesTitle={otherTimeZonesTitle}
            showFloatingOption={showFloatingOption}
            showSystemDefaultOption={showSystemDefaultOption}
          />
        }
        updatePositionStrategy="always"
        className={cn("w-80", className)}
        ref={ref}
        {...rest}
      >
        {children}
      </Popover>
    )
  },
)
TimeZonePickerFn.displayName = "TimeZonePicker"
export const TimeZonePicker = React.memo(
  TimeZonePickerFn,
) as typeof TimeZonePickerFn

export type TimeZoneComboboxProps = {
  selectedZones?: TimeZone[]
  onZoneSelect?: (zone: TimeZone | null) => void
  onReorder?: (zones: TimeZone[]) => void
  selectionMode?: MenuItemGroup["selectionMode"]
  otherTimeZonesTitle?: string
  onComplete?: () => void
  showFloatingOption?: boolean
  showSystemDefaultOption?: boolean
}
const TimeZoneComboboxFn = React.forwardRef(
  (props: TimeZoneComboboxProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const {
      selectedZones,
      onZoneSelect,
      selectionMode,
      onComplete,
      showFloatingOption,
      showSystemDefaultOption,
    } = props

    const t = useTranslations("time_zones")
    const floatingOption = useMemo((): MenuItem | undefined => {
      if (!showFloatingOption) return undefined

      return {
        id: "floating",
        title: t("make_floating"),
        description: t("make_floating_description"),
        icon: "LifeRing",
        onSelect: () => {
          onZoneSelect?.(null)
          onComplete?.()
        },
      }
    }, [showFloatingOption, t, onZoneSelect, onComplete])

    const systemDefaultOption = useMemo(():
      | (MenuItem & { selectionMode: "radio" })
      | undefined => {
      if (!showSystemDefaultOption) return undefined
      return {
        id: "system",
        title: t("system_default"),
        icon: "Globe",
        selected: selectedZones?.length === 0,
        selectionMode: "radio",
        onSelect: () => {
          onZoneSelect?.(null)
          onComplete?.()
        },
      }
    }, [showSystemDefaultOption, t, onZoneSelect, onComplete, selectedZones])

    const root = useTimeZonesMenu(selectedZones, onZoneSelect, selectionMode)

    return (
      <Combobox
        ref={ref}
        root={root}
        onComplete={onComplete}
        pinnedItems={
          floatingOption
            ? [floatingOption]
            : systemDefaultOption
            ? [systemDefaultOption]
            : undefined
        }
      />
    )
  },
)
TimeZoneComboboxFn.displayName = "TimeZoneCombobox"
export const TimeZoneCombobox = React.memo(
  TimeZoneComboboxFn,
) as typeof TimeZoneComboboxFn
