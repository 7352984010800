import { MenuPage } from "@daybridge/components"
import { useCallback, useMemo } from "react"
import { Area } from "../../data/areas/Area"
import { useAreas } from "../../data/areas/useAreas"
import { useClientData } from "../../data/client-data/useClientData"
import { useShowHideArea } from "../../data/areas/useShowHideArea"
import { AreasMenuOptions, useAreasMenu } from "./areas-menu"

export const useAreasShowHideMenu = (
  opts?: Pick<AreasMenuOptions, "creating" | "onCreateComplete">,
): MenuPage => {
  const { data: areas = [] } = useAreas(undefined, { refetchOnMount: false })

  // No area option
  const [webHideItemsWithNoArea, setHideItemsWithNoAreas] = useClientData(
    "webHideItemsWithNoArea",
  )

  // Shown/hidden
  const [hiddenAreaIds] = useClientData("webHiddenAreaIds")
  const showHide = useShowHideArea()
  const onAreaSelect = useCallback(
    (area: Area, selected: boolean) => {
      showHide(area.id, selected)
    },
    [showHide],
  )
  const selectedAreas = useMemo(() => {
    return (
      areas
        ?.filter((area) => !hiddenAreaIds.includes(area.id))
        .map((area) => area.id) ?? []
    )
  }, [areas, hiddenAreaIds])

  const menu = useAreasMenu({
    selectionMode: "checkbox",
    showNoAreaOption: true,
    showAreaEditMenu: true,
    showCalendarCount: true,
    creating: opts?.creating,
    onCreateComplete: opts?.onCreateComplete,
  })

  const boundMenu = useMemo(
    () =>
      menu({
        allowReorder: true,
        selectedAreas,
        onAreaSelect,
        noAreaOptionSelected: !webHideItemsWithNoArea,
        onNoAreaChange: (selected) => {
          setHideItemsWithNoAreas(!selected)
        },
      }),
    [
      menu,
      selectedAreas,
      onAreaSelect,
      webHideItemsWithNoArea,
      setHideItemsWithNoAreas,
    ],
  )

  return boundMenu
}
