import { MenuPage } from "@daybridge/components"
import { signOut } from "next-auth/react"
import { useTranslations } from "next-intl"
import { useMemo, useState } from "react"
import { useDaybridgeMenu } from "../daybridge/daybridge-menu"
import { useDeveloperMenu } from "../developer/developer-menu"
import { useTagsShowHideMenu } from "../tags/tags-show-hide-menu"
import { useDownloadsMenu } from "../settings/downloads-menu"
import { useLanguageMenu } from "../languages/language-menu"
import { useThemeMenu } from "../settings/theme-menu"
import { useTimeFormatMenu } from "../settings/time-format-menu"
import { useLayoutSettingsMenu } from "../layout/layout-settings-menu"
import { useCreateCalendarMenu } from "../calendars/create-calendar-menu"
import { useCountrySettingsMenu } from "../countries/country-settings-menu"
import { useAreasShowHideMenu } from "../areas/areas-show-hide-menu"
import { useCreateAreaMenu } from "../areas/create-area-menu"
import { useCreateTagMenu } from "../tags/create-tag-menu"
import { useConnectedAccountsMenu } from "../connected-accounts/connected-accounts-menu"
import {
  useAdditionalTimeZonesMenu,
  useHomeTimeZoneMenu,
} from "../timezones/time-zones-menu"
import { useLocationsMenu } from "../locations/locations-menu"
import { useCalendarMenu } from "../calendars/calendar-menu"

export const useCommandKMenu = (): MenuPage => {
  const t = useTranslations("search")
  const tEmptyState = useTranslations("default_no_results_state")

  const [creatingArea, setCreatingArea] = useState(false)
  const areasMenu = useAreasShowHideMenu({
    creating: creatingArea,
    onCreateComplete: () => {
      setCreatingArea(false)
    },
  })
  const createAreaMenu = useCreateAreaMenu(() => {
    setCreatingArea(true)
    return "areas"
  })
  const tAreas = useTranslations("areas")

  const [creatingTag, setCreatingTag] = useState(false)
  const tagsMenu = useTagsShowHideMenu({
    creating: creatingTag,
    onCreateComplete: () => {
      setCreatingTag(false)
    },
  })
  const createTagMenu = useCreateTagMenu(() => {
    setCreatingTag(true)
    return "tags"
  })
  const tTags = useTranslations("tags")

  const calendarsMenu = useCalendarMenu()
  const createCalendarMenu = useCreateCalendarMenu()
  const tCalendars = useTranslations("calendars")

  const connectedAccountsMenu = useConnectedAccountsMenu()()
  const tConnectedAccounts = useTranslations("connected_accounts")

  const tLocations = useTranslations("locations")
  const locationsMenu = useLocationsMenu()()
  const layoutSettingsMenu = useLayoutSettingsMenu()

  const tSettings = useTranslations("settings")
  const tTimeZones = useTranslations("time_zones")
  const homeTimeZoneMenu = useHomeTimeZoneMenu()
  const additionalTimeZonesMenu = useAdditionalTimeZonesMenu()
  const timeFormatMenu = useTimeFormatMenu()
  const tTimeFormat = useTranslations("clock_format")
  const themeMenu = useThemeMenu()
  const tTheme = useTranslations("theme")
  const countryMenu = useCountrySettingsMenu()
  const tCountries = useTranslations("countries")
  const languageMenu = useLanguageMenu()
  const tLanguage = useTranslations("language")

  const tAccount = useTranslations("account")
  const tLogOut = useTranslations("log_in_out")

  const tDownloads = useTranslations("downloads")
  const downloadsMenu = useDownloadsMenu()

  const daybridgeMenu = useDaybridgeMenu()
  const developerMenu = useDeveloperMenu()

  return useMemo(
    (): MenuPage => ({
      prompt: t("search") + "...",
      promptIcon: "Search",
      noResultsTitle: tEmptyState("no_results"),
      noResultsDescription: tEmptyState("no_results_description"),
      items: [
        {
          groupId: "areas",
          title: tAreas("areas"),
          items: [
            {
              id: "areas",
              title: tAreas("areas"),
              icon: "Area",
              children: areasMenu,
            },
            createAreaMenu,
          ],
        },
        {
          groupId: "calendars",
          title: tCalendars("calendars"),
          items: [
            {
              id: "calendars",
              title: tCalendars("calendars"),
              icon: "Calendar",
              children: calendarsMenu(),
            },
            createCalendarMenu,
          ],
        },
        {
          groupId: "tags",
          title: tTags("tags"),
          items: [
            {
              id: "tags",
              title: tTags("tags"),
              icon: "Tag",
              children: tagsMenu,
            },
            createTagMenu,
          ],
        },
        {
          groupId: "connected-accounts",
          title: tConnectedAccounts("connected_accounts"),
          items: [
            {
              id: "manage-connected-accounts",
              title: tConnectedAccounts("connected_accounts"),
              icon: "Connection",
              children: connectedAccountsMenu,
            },
          ],
        },
        {
          groupId: "locations",
          title: tLocations("saved_locations"),
          items: [
            {
              id: "locations",
              title: tLocations("saved_locations"),
              icon: "MapPin",
              children: locationsMenu,
            },
          ],
        },
        layoutSettingsMenu,
        {
          groupId: "settings",
          title: tSettings("settings"),
          items: [
            {
              id: "home-time-zone",
              title: tTimeZones("home_time_zone"),
              icon: "Home",
              children: homeTimeZoneMenu,
            },
            {
              id: "time-zones",
              title: tTimeZones("additional_time_zones"),
              icon: "Globe",
              children: additionalTimeZonesMenu,
            },
            {
              id: "time-format",
              title: tTimeFormat("clock_format"),
              icon: "Clock",
              children: timeFormatMenu,
            },
            {
              id: "switch-theme",
              title: tTheme("theme"),
              icon: "Theme",
              children: themeMenu,
            },
            {
              id: "country",
              title: tCountries("country"),
              icon: "World",
              children: countryMenu,
            },
            {
              id: "language",
              title: tLanguage("language"),
              icon: "Language",
              children: languageMenu,
            },
          ],
        },
        {
          groupId: "account",
          title: tAccount("account"),
          items: [
            {
              id: "sign_out",
              title: tLogOut("log_out"),
              icon: "SignOut",
              onSelect: () => void signOut(),
            },
          ],
        },
        {
          groupId: "downloads",
          title: tDownloads("downloads"),
          items: [
            {
              id: "mobile-apps",
              title: tDownloads("mobile_apps"),
              icon: "Smartphone",
              children: downloadsMenu,
            },
          ],
        },
        daybridgeMenu,
      ].concat(
        process.env.NEXT_PUBLIC_VERCEL_ENV !== "production"
          ? [developerMenu]
          : [],
      ),
    }),
    [
      calendarsMenu,
      createCalendarMenu,
      developerMenu,
      downloadsMenu,
      tagsMenu,
      createTagMenu,
      areasMenu,
      createAreaMenu,
      themeMenu,
      languageMenu,
      daybridgeMenu,
      connectedAccountsMenu,
      t,
      tAccount,
      tAreas,
      tCalendars,
      tDownloads,
      tEmptyState,
      tTags,
      tLanguage,
      tLogOut,
      tSettings,
      tTheme,
      timeFormatMenu,
      tTimeFormat,
      layoutSettingsMenu,
      tConnectedAccounts,
      tTimeZones,
      additionalTimeZonesMenu,
      homeTimeZoneMenu,
      countryMenu,
      tCountries,
      locationsMenu,
      tLocations,
    ],
  )
}
