import { getSession } from "next-auth/react"
import { useCallback, useMemo } from "react"
import { MenuItemGroup, useToast } from "@daybridge/components"
import { useAccount } from "../../data/account/useAccount"
import { useBaseURL } from "../../app/[locale]/(boundary)/_providers/BaseURLProvider"
import { useUpdateAccount } from "../../data/account/useUpdateAccount"

export const useDeveloperMenu = (): MenuItemGroup => {
  const toast = useToast()

  const { data: user } = useAccount()

  // Set API base URL
  const baseURL = useBaseURL()

  // Reset onboarding status
  const { mutate: updateAccount } = useUpdateAccount()
  const resetOnboarding = useCallback(() => {
    if (confirm("Reset onboarding status?")) {
      updateAccount({ input: { patch: { onboardingCompleted: false } } })
      window.location.reload()
    }
  }, [updateAccount])

  // Callback to copy the access token to the clipboard
  const copyAccessToken = useCallback(async () => {
    const sessionData = await getSession()
    if (!sessionData) return

    const at = (sessionData as unknown as { accessToken: string }).accessToken
    if (at) {
      void navigator.clipboard.writeText(at).then(() => {
        toast.success({ title: "Copied access token to clipboard" })
      })
    }
  }, [toast])

  const copyUserId = useCallback(() => {
    const userId = user?.id
    if (userId) {
      void navigator.clipboard.writeText(userId).then(() => {
        toast.success({ title: "Copied user ID to clipboard" })
      })
    }
  }, [user, toast])

  const items = useMemo(
    (): MenuItemGroup => ({
      groupId: "developer",
      title: "Developer",
      items: [
        {
          id: "copy_access_token",
          title: "Copy access token",
          icon: "PadlockLocked",
          hue: 0,
          onSelect: () => void copyAccessToken(),
        },
        {
          id: "copy_user_id",
          title: "Copy User ID",
          icon: "User",
          hue: 0,
          onSelect: copyUserId,
        },
        {
          id: "reset_onboarding",
          title: "Reset onboarding status",
          icon: "Undo",
          hue: 0,
          onSelect: resetOnboarding,
        },
        {
          id: "base_url",
          title: baseURL.overrideApplied
            ? "Change API endpoint override"
            : "Set API endpoint override",
          icon: "Code",
          hue: 0,
          onSelect: () => {
            const value = prompt("Enter new API Base URL", baseURL.baseURL)
            if (value) {
              baseURL.setBaseURLOverride(value)
            }
          },
        },
        ...(baseURL.overrideApplied
          ? [
              {
                id: "clear_base_url_override",
                title: "Clear API endpoint override",
                description: baseURL.baseURL,
                icon: "Cross",
                hue: 0,
                onSelect: () => baseURL.clearBaseURLOverride(),
              },
            ]
          : []),
      ],
    }),
    [copyAccessToken, copyUserId, baseURL, resetOnboarding],
  )

  return items
}
