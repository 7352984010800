import { MenuPage } from "@daybridge/components"
import { useCallback, useMemo } from "react"
import { Tag } from "../../data/tags/Tag"
import { useTags } from "../../data/tags/useTags"
import { useClientData } from "../../data/client-data/useClientData"
import { useShowHideTag } from "../../data/tags/useShowHideTag"
import { TagsMenuOptions, useTagsMenu } from "./tags-menu"

export const useTagsShowHideMenu = (
  opts?: Pick<TagsMenuOptions, "creating" | "onCreateComplete">,
): MenuPage => {
  const { data: tags = [] } = useTags(undefined, { refetchOnMount: false })

  // No tag option
  const [webHideItemsWithNoTags, setHideItemsWithNoTags] = useClientData(
    "webHideItemsWithNoTags",
  )

  // Shown/hidden
  const [hiddenTagIds] = useClientData("webHiddenTagIds")
  const showHide = useShowHideTag()
  const onTagSelect = useCallback(
    (tag: Tag, selected: boolean) => {
      showHide(tag.id, selected)
    },
    [showHide],
  )
  const selectedTags = useMemo(() => {
    return (
      tags
        ?.filter((tag) => !hiddenTagIds.includes(tag.id))
        .map((tag) => tag.id) ?? []
    )
  }, [tags, hiddenTagIds])

  const menu = useTagsMenu({
    selectionMode: "checkbox",
    showNoTagOption: true,
    noTagOptionSelected: !webHideItemsWithNoTags,
    onNoTagChange: (selected) => {
      setHideItemsWithNoTags(!selected)
    },
    showTagEditMenu: true,
    partitioned: false,
    creating: opts?.creating,
    onCreateComplete: opts?.onCreateComplete,
  })

  const boundMenu = useMemo(
    () => menu({ allowReorder: true, selectedTags, onTagSelect }),
    [menu, selectedTags, onTagSelect],
  )

  return boundMenu
}
