import React, { ComponentProps } from "react"
import { cn } from "@daybridge/cn"
import { Button } from "@daybridge/components"

type FormButtonProps = ComponentProps<typeof Button> & {
  description?: React.ReactNode
}

const FormButtonFn = React.forwardRef(
  (props: FormButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const { children, className, description, ...rest } = props

    return (
      <Button
        ref={ref}
        variant="translucent"
        className={cn(
          "w-full rounded-none py-3",
          "[&>svg]:text-low-contrast [&>svg]:hover:text-medium-contrast [&>svg]:mr-4 [&>svg]:w-3",
          "[&>svg]:transition [&>svg]:duration-200",
          "focus-visible:ring-inset focus-visible:ring-offset-0",
          "hover:bg-tint-extra-light",
          className,
        )}
        {...rest}
      >
        <div className="w-full truncate flex flex-col">
          <div className="text-left flex-1 truncate">{children}</div>
          {description && (
            <div className="text-left text-medium-contrast text-xs truncate">
              {description}
            </div>
          )}
        </div>
      </Button>
    )
  },
)
FormButtonFn.displayName = "FormButton"

export const FormButton = React.memo(FormButtonFn) as typeof FormButtonFn
