import { useCallback } from "react"
import { useClientData } from "../client-data/useClientData"

export const useShowHideArea = () => {
  const [hiddenAreas, setHiddenAreas] = useClientData("webHiddenAreaIds")

  return useCallback(
    (AreaId: string, displayed: boolean) => {
      // Check if the Area is already in the list of hidden Areas
      const alreadyHidden = hiddenAreas.includes(AreaId)

      // If the Area is already hidden and we want to hide it, do nothing
      if (alreadyHidden && !displayed) return

      // If the Area is not hidden and we want to show it, do nothing
      if (!alreadyHidden && displayed) return

      // Otherwise, update the list of hidden Areas
      const newHiddenAreas = displayed
        ? hiddenAreas.filter((id) => id !== AreaId)
        : [...hiddenAreas, AreaId]

      return setHiddenAreas(newHiddenAreas)
    },
    [setHiddenAreas, hiddenAreas],
  )
}
