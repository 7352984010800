import { MenuPage } from "@daybridge/components"
import { useTranslations } from "next-intl"
import { useCallback, useMemo } from "react"

export const useDownloadsMenu = (): MenuPage => {
  const t = useTranslations("downloads")

  const onAppStoreSelect = useCallback(() => {
    window.open("/download/ios", "_blank")
  }, [])

  const onPlayStoreSelect = useCallback(() => {
    window.open("/download/android", "_blank")
  }, [])

  const onTestFlightSelect = useCallback(() => {
    window.open("/download/ios-testflight", "_blank")
  }, [])

  const onBetaChannelSelect = useCallback(() => {
    window.open("/download/android-beta", "_blank")
  }, [])

  return useMemo(
    (): MenuPage => ({
      prompt: t("choose_platform"),
      promptIcon: "Smartphone",
      items: [
        {
          id: "ios",
          title: t("ios"),
          icon: "Apple",
          onSelect: onAppStoreSelect,
        },
        {
          id: "android",
          title: t("android"),
          icon: "Android",
          onSelect: onPlayStoreSelect,
        },
        {
          groupId: "early-releases",
          title: t("early_releases"),
          items: [
            {
              id: "testflight",
              title: t("testflight"),
              icon: "Apple",
              onSelect: onTestFlightSelect,
            },
            {
              id: "beta-channel",
              title: t("beta_channel"),
              icon: "Android",
              onSelect: onBetaChannelSelect,
              description: t("opt_in_via_play_store"),
            },
          ],
        },
      ],
    }),
    [
      t,
      onAppStoreSelect,
      onPlayStoreSelect,
      onTestFlightSelect,
      onBetaChannelSelect,
    ],
  )
}
