import { cn } from "@daybridge/cn"
import React from "react"

type FormSectionProps = React.HTMLAttributes<HTMLDivElement> & {
  // Additional props here...
}

const FormSectionFn = React.forwardRef(
  (props: FormSectionProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { children, className, ...rest } = props
    return (
      <div ref={ref} className={cn("relative group", className)} {...rest}>
        <div className="line h-px absolute right-0 left-10 group-hover:left-0 bg-tint-light" />
        {children}
      </div>
    )
  },
)
FormSectionFn.displayName = "FormSection"

export const FormSection = React.memo(FormSectionFn) as typeof FormSectionFn
